<template>
    <div class="wrap" :class="{ wrap_en: $i18n.locale == 'EN' }">
        <my-header></my-header>
        <div class="section">
            <div class="banner">
                <p>{{ $t('Public.NEVService.banner.title') }}</p>
            </div>
            <div class="content">
                <div class="service">
                    <p class="detail_title">{{ $t('Public.NEVService.extended.title') }}</p>
                    <div class="service_info">
                        <div class="service_text">
                            {{ $t('Public.NEVService.extended.intro') }}
                        </div>
                        <ul>
                            <li>
                                <div class="service_icon"><img src="../../public/images/service/nev/solution_icon5.png" /></div>
                                <div class="service_intro">
                                    <p>{{ $t('Public.NEVService.extended.service.battery1') }}</p>
                                    <div>{{ $t('Public.NEVService.extended.service.battery2') }}</div>
                                </div>
                            </li>
                            <li>
                                <div class="service_icon"><img src="../../public/images/service/nev/solution_icon6.png" /></div>
                                <div class="service_intro">
                                    <p>{{ $t('Public.NEVService.extended.service.warranty1') }}</p>
                                    <div>{{ $t('Public.NEVService.extended.service.warranty2') }}</div>
                                </div>
                            </li>
                            <li>
                                <div class="service_icon"><img src="../../public/images/service/nev/solution_icon7.png" /></div>
                                <div class="service_intro">
                                    <p>{{ $t('Public.NEVService.extended.service.warranty3') }}</p>
                                    <div>{{ $t('Public.NEVService.extended.service.warranty4') }}</div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="solution">
                    <p class="detail_title">{{ $t('Public.NEVService.solutions.title') }}</p>
                    <div class="solution_text">
                        {{ $t('Public.NEVService.solutions.intro') }}
                    </div>
                    <ul>
                        <li v-for="(item, index) in $t('Public.NEVService.solutions.list')" :key="index">
                            <div class="service_icon">
                                <img :src="require('../../public/images/service/nev/solution_icon' + (index + 1) + '.png')" />
                            </div>
                            <p>{{ item.title }}</p>
                            <p>{{ item.title2 }}</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <my-footer></my-footer>
    </div>
</template>

<script>
// @ is an alias to /src
import MyHeader from '@/components/MyHeader.vue'
import MyFooter from '@/components/MyFooter.vue'

export default {
    name: 'NEVService',
    components: {
        MyHeader,
        MyFooter
    },
    data() {
        return {}
    },
    methods: {},
    mounted() {}
}
</script>
<style scoped>
.service {
    width: 100%;
    /* height:6rem;
		background: url(../../public/images/service/nev/service_bg.png) no-repeat;
		background-size: 100% 100%; */
    padding: 1rem 0.2rem 0;
}
.service_text {
    font-size: 0.28rem;
    margin: 0.8rem 0 0.4rem;
    line-height: 1.5;
    text-indent: 2em;
}
.service_info ul {
    /* display:flex; */
    /* width:6.5rem ; */
    margin: 0.4rem auto 0;
    /* justify-content: space-between; */
}
.service_info li {
    width: 100%;
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
    line-height: 1.5;
    margin-bottom: 0.35rem;
}
.service_icon {
    width: 0.8rem;
}
.service_intro {
    width: 6.04rem;
    color: #999;
}
.service_intro p {
    font-size: 0.28rem;
    font-weight: 600;
    color: #333333;
    line-height: 2.67;
}
.wrap_en .service_intro p {
    font-weight: 400;
}
.banner {
    width: 100%;
    height: 4.4rem;
    padding: 1.88rem 0 0 0.77rem;
    background: url(../../public/images/service/nev/banner.jpg) no-repeat;
    background-size: 100% 100%;
    background-color: #0c1f3d;
}
.banner p {
    font-size: 0.46rem;
    line-height: 1.39;
    color: #fff;
}
.solution {
    /* background:#F4F3F3; */
    padding: 0.5rem 0.2rem 0.6rem;
}
.solution_text {
    width: 6.96rem;
    margin: 0.7rem auto 0.54rem;
    line-height: 1.71;
    text-align: justify;
    text-indent: 2em;
}
.wrap_en .solution_text {
    text-align: left;
    text-align: justify;
}
.solution ul {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.solution li {
    width: 3.44rem;
    min-height: 2.1rem;
    background: #ffffff;
    padding: 0.25rem 0.1rem 0.14rem 0.25rem;
    font-size: 0.3rem;
    margin-bottom: 0.23rem;
}
.wrap_en .solution li {
    padding: 0.25rem 0.12rem 0.14rem;
}
.wrap_en .service_icon {
    margin-left: 0.15rem;
}
.wrap_en .service_info li p {
    line-height: 1.5;
    width: 5.8rem;
}
.solution li p {
    line-height: 1.4;
}
.solution li div {
    width: 0.66rem;
    margin-bottom: 0.26rem;
}
.detail_title {
    font-size: 0.42rem;
    font-weight: 500;
    text-align: center;
    width: 6rem;
    margin: 0 auto;
}
.wrap_en .service_info ul {
    display: block;
}
.wrap_en .service_info li {
    width: 100%;
    justify-content: flex-start;
    margin-bottom: 0.44rem;
}
.wrap_en .service {
    padding-top: 0.34rem;
}
.wrap_en .service_text {
    margin-top: 0.3rem;
    text-align: justify;
}
.wrap_en .service_info .service_icon {
    margin: 0 0.16rem 0 0;
}
</style>
